<template>
  <table-view v-loading='loading' :show-page='false'>
    <template #header>
      <view-search-form :queryInfo.sync='queryInfo' :tool-list="['grade']" :show-clear-btn='false'
        @on-search='renderTable' />
      <div class='header-button'>
        <el-button type='primary' size='small' @click='handleDownloadStu()'>导出学生名单</el-button>
      </div>
    </template>
    <el-table :data='tableData' style='width: 100%' border stripe>
      <el-table-column prop='grade' width='100' label='年级' />
      <el-table-column prop='majorName' width='300' label='专业' />
      <el-table-column :label='`第一学年(${tableDataCount.payNumOne + tableDataCount.unPayNumOne})`'>
        <el-table-column prop='payNumOne' :label='`已缴费人数(${tableDataCount.payNumOne})`' />
        <el-table-column prop='unPayNumOne' :label='`未缴费人数(${tableDataCount.unPayNumOne})`' />
      </el-table-column>
      <el-table-column :label='`第二学年(${tableDataCount.payNumTwo + tableDataCount.unPayNumTwo})`'>
        <el-table-column prop='payNumTwo' :label='`已缴费人数(${tableDataCount.payNumTwo})`' />
        <el-table-column prop='unPayNumTwo' :label='`未缴费人数(${tableDataCount.unPayNumTwo})`' />
      </el-table-column>
      <el-table-column :label='`第三学年(${tableDataCount.payNumThree + tableDataCount.unPayNumThree})`'>
        <el-table-column prop='payNumThree' :label='`已缴费人数(${tableDataCount.payNumThree})`' />
        <el-table-column prop='unPayNumThree' :label='`未缴费人数(${tableDataCount.unPayNumThree})`' />
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import { getPartTimePayCount } from '@/api/studentPartTime'
import { cloneDeep } from 'lodash'

export default {
  name: 'pattimeStuPayCount',
  data() {
    return {
      loading: false,
      queryInfo: {
        grade: new Date().getFullYear()
      },
      tableData: [],
      tableDataCount: {
        payNumOne: 0,
        unPayNumOne: 0,
        payNumTwo: 0,
        unPayNumTwo: 0,
        payNumThree: 0,
        unPayNumThree: 0
      }
    }
  },
  mounted() {
    this.renderTable()
    this.tableDataCountCopy = cloneDeep(this.tableDataCount)
  },
  methods: {
    renderTable() {
      if (!this.queryInfo.grade) {
        this.$message.error('请先选择年级!')
        this.tableData = []
        return
      }
      this.loading = true
      getPartTimePayCount(this.queryInfo).then(res => {
        this.tableData = res.data
        this.getTableDataCount()
      }).catch().finally(() => {
        this.loading = false
      })
    },
    // 计算统计数据
    getTableDataCount() {
      this.$nextTick(() => {
        this.tableDataCount = cloneDeep(this.tableDataCountCopy)
        this.tableData.forEach(item => {
          this.tableDataCount.payNumOne += Number(item.payNumOne)
          this.tableDataCount.unPayNumOne += Number(item.unPayNumOne)
          this.tableDataCount.payNumTwo += Number(item.payNumTwo)
          this.tableDataCount.unPayNumTwo += Number(item.unPayNumTwo)
          this.tableDataCount.payNumThree += Number(item.payNumThree)
          this.tableDataCount.unPayNumThree += Number(item.unPayNumThree)
        })
      })
    },
    handleDownloadStu() {
      this.$http.exportExcelHttp
        .downloadPartTimeStu(this.queryInfo)
        .then()
    }
  }
}
</script>
